import React from "react";
import ReactDOM from "react-dom";

import "./sass/base.scss";
import App from "./App";
import './static/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf';
import './static/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf';
import './static/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf';
import './static/fonts/IBM_Plex_Serif/IBMPlexSerif-Medium.ttf';
import './static/fonts/IBM_Plex_Serif/IBMPlexSerif-Bold.ttf';

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <App/>,
    window.document.getElementById("app")
);

serviceWorker.unregister();
